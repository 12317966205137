
import Vue from "vue";
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';;
import { Info, Success, Warn } from "@/services/notif-utils";

export default Vue.extend({
  name: "ModalGlossary",
  props: { show: Boolean },
  data() {
    return {
      searchTerm: "",
    };
  },
  computed: {
    filteredSubVars(): any {
      return this.glossary.filter((var_: any) => {
        return var_.term
          .toLowerCase()
          .includes(this.searchTerm.toLocaleLowerCase());
      });
    },
    glossary(): any[] {
      const subVars = this.$store.state.Message.glossary;
      return subVars;
    },
    showModal(): boolean {
      return this.show;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    copy(text: string) {
      copyToClipboard(text)
        .then(() => {
          Info(`${text} Copied to clipboard successfully :)`);
          this.$emit("close");
        })
        .catch(() => {
          Warn("Failed to copy :(");
        });
    },
  },
});

import { render, staticRenderFns } from "./TopBar.vue?vue&type=template&id=2f736471&scoped=true"
import script from "./TopBar.vue?vue&type=script&lang=ts"
export * from "./TopBar.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f736471",
  null
  
)

export default component.exports
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QHeader,QToolbar,QBtn,QToolbarTitle,QInput,QIcon,QBtnDropdown,QList,QItemLabel,QSeparator,QItem,QItemSection,QCheckbox,QSpace,QChip,QSpinnerGears,QTooltip,QBadge,QAvatar,QMenu});qInstall(component, 'directives', {ClosePopup});

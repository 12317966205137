
import Vue from "vue";
import SideBar from "../components/SideBar.vue";
import TopBar from "../components/TopBar.vue";
import OfflineBanner from "@/components/OfflineBanner.vue";
export default Vue.extend({
  name: "MainLayout",
  components: {
    SideBar,
    TopBar,
    OfflineBanner,
  },
  data() {
    return {
      toggle: true,
    };
  },
  watch: {
    $route() {
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
  },
  methods: {
    toggler() {
      this.toggle = !this.toggle;
      //   return (this.toggle = event);
    },
  },
});


import Vue from "vue";
export default Vue.extend({
  name: "OfflineBanner",
  data() {
    return {
      isOffline: false
    };
  },
  methods: {
    setTrue() {
      this.isOffline = true;
    },
    setFalse() {
      this.isOffline = false;
    }
  },
  created() {
    window.addEventListener("offline", this.setTrue);
    window.addEventListener("online", this.setFalse);
  },
  beforeDestroy() {
    window.removeEventListener("offline", this.setTrue);
    window.removeEventListener("online", this.setFalse);
  }
});


import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import ComposeMessageModal from "@/components/ComposeMessageModal.vue";
// import {AuthState}
export default Vue.extend({
  name: "SideBar",
  components: { ComposeMessageModal },
  props: { drawerOpen: Boolean },
  data() {
    return {
      stateDrawer: false,
      composeMessage: false,
    };
  },

  computed: {
    ...mapGetters({
      email: "Auth/userEmail",
      isLoggedIn: "Auth/isLoggedIn",
      isManager: "Auth/isManager",
      unreadCount: "Message/unreadMessagesCount",
    }),

    toggleState: function (): boolean {
      return this.drawerOpen;
    },
    oqCount() {
      const count = this.$store.state.Message.unprocessedOutboundQueueCount;
      if (count) {
        return count;
      }
      return 0;
    },
  },
});


import Vue from "vue";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import { ActionTypes } from "@/store/auth/action-types";
import { DataState } from "@/services/data-states";
import ModalGlossary from "./ModalGlossary.vue";
import { SessionActionNames as SSC } from "@/models/SessionStatsConstants";
import { Warn, Info } from "@/services/notif-utils";
import SSA from "@/services/session-helper";
import api from "@/api";
interface IconObj {
  message: string;
  icon?: string;
  type?: string | "default";
  hasSpinner: boolean;
}
export default Vue.extend({
  name: "TopBar",
  components: {
    ModalGlossary,
  },
  data() {
    return {
      clearingCache: false,
      drawerOpen: true,
      darkModeCheck: false,
      searchType: [],
      searchTerm: "",
      darkModeTooltip: "",
      showGlossaryModal: false,
    };
  },

  computed: {
    ...mapGetters({
      unreadCountGetter: "Message/unreadMessagesCount",
      bufferLoading: "Buffer/getBufferLoading",
      bufferState: "Buffer/getBufferState",
    }),
    currentRouteName(): string {
      const name = this.$route.name?.toString();

      if (name) {
        return name;
      }
      return "";
    },
    shipsiteTokenInLocal(): boolean {
      const token = localStorage.getItem("shipsiteToken");
      if (token) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      logoutAction: "Auth/" + ActionTypes.LOGOUT_USER,
    }),
    reload() {
      window.location.reload();
    },
    setBufferStateBtn(): IconObj {
      const bState = this.bufferState;

      let obj: IconObj;
      switch (bState) {
        case DataState.SUCCESS:
          obj = {
            message: "Ready",
            icon: "mark_chat_read",
            hasSpinner: false,
          };
          return obj;
        case DataState.LOADING:
          obj = {
            message: "Loading Next Message",
            icon: "",
            hasSpinner: true,
          };
          return obj;
        case DataState.ERROR:
          obj = {
            message: "Oops Error Loading..",
            icon: "warning",
            hasSpinner: false,
          };
          return obj;

        default:
          obj = {
            message: "Buffer init",
            icon: "",
            hasSpinner: false,
          };
          return obj;
      }
      // return {}
    },
    getBufferLabel() {
      const buttonDetails = this.setBufferStateBtn();
      if (buttonDetails) {
        return buttonDetails.message;
      }
      return;
    },
    getBufferIcon() {
      const buttonDetails = this.setBufferStateBtn();
    },
    darkMode() {
      this.$q.dark.toggle();
      const state = "dark";
      if (!this.$q.dark.isActive) {
        return localStorage.setItem("theme", "light");
      }
      localStorage.setItem("theme", state);
    },
    iconColor() {
      if (this.$q.dark.isActive) {
        return "amber";
      }
      return "";
    },
    iconWatch() {
      if (this.$q.dark.isActive == true) {
        this.darkModeTooltip = "Light mode";
        return "light_mode";
      }
      this.darkModeTooltip = "Dark mode zzzz...";
      return "nights_stay";
    },
    color() {
      const dark = this.$q.dark.isActive;
      if (dark) {
        return "bg-dark text-grey";
      } else {
        return "bg-white text-dark";
      }
    },
    async logout() {
      await SSA.setStatAction(SSC.END).catch((error) => {
        console.log(error);
        Warn("Unable to end sesssion");
      });
      this.logoutAction()
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          this.$q.notify({
            position: "top-right",
            type: "warning",
            message: "Logout Error",
          });
        });
    },
    purgeCache() {
      this.clearingCache = true;
      api.Staff.purgeCache()
        .then((response) => {
          Info("Purging Cache...");
          this.clearingCache = false;
          location.reload();
        })
        .catch((err) => {
          console.log(err);
          Warn("Unable to Purge Cache");
          this.clearingCache = false;
        });
    },
  },
});

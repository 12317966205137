
import Vue from "vue";
export default Vue.extend({
  name: "ComposeMessageModal",
  props: {
    openModal: Boolean
  },
  data() {
    return {
      modal: false,
      isFullScreen: false,
      topic: "",
      to: "",
      message: ""
    };
  },
  methods: {
    openComposeMessageModal() {
      return (this.modal = true);
    }
  }
});
